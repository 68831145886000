import { useLocation, useNavigate } from 'react-router';
import { createBrowserHistory } from 'history';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import Swal from 'sweetalert2';

import activity from '../../../assets/img/icon/activity.svg';
import transaction from '../../../assets/img/icon/transaction.svg';
import voucherIcon from '../../../assets/img/icon/my-voucher.svg';
// import cv from '../../../assets/img/icon/cv.svg';
import edit from '../../../assets/img/icon/edit.svg';
import pass from '../../../assets/img/icon/pass.svg';
import { logout, resetStatus } from '../../../store/redux/actions/auth/login';

const NavigationMenu = () => {
  const navigate = useNavigate();
  const history = createBrowserHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const logoutStatus = useSelector((state) => state.login.status);

  useEffect(() => {
    if (logoutStatus === 'logged out') {
      Swal.fire({
        icon: 'success',
        title: 'Success',
        text: 'You are logged out!',
      }).then((res) => {
        if (res.isConfirmed) {
          dispatch(resetStatus());
        } else if (res.isDismissed) {
          dispatch(resetStatus());
        }
      });
      localStorage.clear();
    } else if (logoutStatus === 'success logout') {
      navigate('/');
      history.go(0);
    }
  }, [dispatch, history, logoutStatus, navigate]);

  const menuList = [
    {
      title: 'My Courses',
      icon: activity,
      link: ['/profile/mycourse', '/profile/detailcourse', '/payment'],
      action: (e) => {
        e.preventDefault();
        navigate('/profile/mycourse');
        history.go(0);
      },
    },
    {
      title: 'My Transactions',
      icon: transaction,
      link: ['/profile/transaction'],
      action: () => navigate('/profile/transaction'),
    },
    {
      title: 'My Vouchers',
      icon: voucherIcon,
      link: ['/profile/voucher'],
      action: (e) => {
        e.preventDefault();
        navigate('/profile/voucher');
        history.go(0);
      },
    },
    // {
    //   title: 'My Curriculum Vitae',
    //   icon: cv,
    //   link: ['/profile/cv'],
    //   action: (e) => {
    //     e.preventDefault();
    //     navigate('/profile/cv');
    //     history.go(0);
    //   },
    // },
    {
      title: 'Edit Profile',
      icon: edit,
      link: ['/profile/edit'],
      action: (e) => {
        e.preventDefault();
        navigate('/profile/edit');
        history.go(0);
      },
    },
    {
      title: 'Change Password',
      icon: pass,
      link: ['/profile/changepassword'],
      action: (e) => {
        e.preventDefault();
        navigate('/profile/changepassword');
      },
    },
  ];

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <div className='profile-menu'>
      {menuList.map((item, index) => {
        return (
          <a
            key={index}
            onClick={item.action}
            className={`cursor-pointer ${
              item.link.includes(location.pathname) ? 'active' : ''
            }`}
          >
            <p>
              <span>
                <img src={item.icon} alt={Object.keys(item.icon)[0]} />
              </span>
              {item.title}
            </p>
          </a>
        );
      })}
      <button
        type='button'
        className='btn btn-outline-green'
        onClick={handleLogout}
      >
        Logout
      </button>
    </div>
  );
};

export default NavigationMenu;
